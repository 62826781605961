import { gsap } from "gsap/dist/gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export const animationsHandler = {

    init: () => {
        animationsHandler.smoothNav();

        /** GSAP Animations */
        animationsHandler.animIntro();
        animationsHandler.animHerokeywords();
        animationsHandler.animShowcase();
        animationsHandler.animKeywordsLine();
        animationsHandler.animExpertises();
        animationsHandler.animProjects();
        animationsHandler.animTeam();
        // animationsHandler.strateContact();

        animationsHandler.animPages();

    },

    smoothNav: () => {
        let $headerNav = document.querySelectorAll('#header-main a');

        for (var i = 0; i < $headerNav.length; i++) {
            $headerNav[i].addEventListener('click', (e) => {
                let $target = e.target.closest('a').getAttribute("href");

                if (!$target.startsWith('#')) {
                    return;
                } else {
                    e.preventDefault();
                    let goToOffset;

                    if ($target !== '#') {
                        let sectionRect = document.querySelector($target).getBoundingClientRect();
                        goToOffset = $target === '#contact' ? document.body.scrollHeight : sectionRect.top + window.scrollY;
                    } else {
                        goToOffset = 0;
                    }

                    window.scroll({
                        top: goToOffset,
                        behavior: 'smooth'
                    })
                }
            })
        }
    },

    animPages: () => {

        let $page = document.querySelector('.page .container');
        if (!$page) return;
        setTimeout(() => {
            $page.classList.remove('intro');
        }, 600);

    },
    animIntro: () => {

        let $header = document.querySelector('#header-main');
        setTimeout(() => {
            $header.classList.remove('intro');
        }, 300);

        // $header.classList.add('intro')
        // $keyword.classList.add('intro')
        // $baseline.classList.add('intro')
        // $excerpt.classList.add('intro')


        let $hero = document.querySelector('#hero');
        if (!$hero) return;

        let $keyword = $hero.querySelector('.title-1 .kw-visible');
        let $baseline = $hero.querySelector('.hero__baseline');
        let $excerpt = document.querySelector('.hero__excerpt ');

        setTimeout(() => {
            $keyword.classList.remove('kw-intro');
        }, 600);
        setTimeout(() => {
            $baseline.classList.remove('intro');
        }, 700);
        setTimeout(() => {
            $excerpt.classList.remove('intro');
        }, 850);

        // let tl = gsap.timeline({
        //     // autoAlpha: 0,
        //     // scrollTrigger: {
        //     //     trigger: section,
        //     //     start: 'top 50%',
        //     //     scrub: true,
        //     //     end: '+=500',
        //     //     markers: true
        //     // }
        //     onComplete: () => {
        //         // $keyword.classList.remove('kw-intro');
        //         // $keyword.removeAttribute('style');
        //     }
        // })
        //     .addLabel("start")
        //     // .fromTo($keyword, { transitionDelay: 0, opacity: 0, y: 100, rotate: -6 }, { transitionDelay: 0, opacity: 1, y: 0, rotate: 0, duration: 0.8, delay: 1 })
        //     // .fromTo($excerpt, { opacity: 0, y: 60 }, { opacity: 1, y: 0, duration: 0.8 })
        //     .addLabel("end");


    },
    animHerokeywords: () => {
        let $keywordsList = document.querySelector('.hero__keywords');
        let $keywords = document.querySelectorAll('.hero__keywords>span');
        // let $keywordsCount = $keywords.length;

        // for (let index = 0; index < $keywords.length; index++) {
        //     const offset = $keywords[index].offsetTop;
        //     // console.log(offset);
        //     $keywords[index].dataset.offset = offset;
        // }

        let i = 2; // Start to Keyword 2
        setInterval(() => {
            // cloneKeywords();
            goTo(i);
            i = i < $keywords.length ? i + 1 : 1;
        }, 4000);

        // states -100% 0 100%
        function goTo(position) {
            let nextposition = i < $keywords.length ? position + 1 : 1;
            let gotoItem = $keywordsList.querySelector("span:nth-of-type(" + position + ")");
            let nextItem = $keywordsList.querySelector("span:nth-of-type(" + nextposition + ")");
            // console.log(gotoItem, nextItem)

            gotoItem.dataset.kwPosition = "current";
            gotoItem.classList.add('kw-visible');
            gotoItem.classList.remove('kw-hidden', '-kw-hidden');

            nextItem.dataset.kwPosition = "next";
            nextItem.classList.add('kw-hidden');
            nextItem.classList.remove('kw-visible', '-kw-hidden');

            let $replaceItems = Array.from($keywords).filter(node => node !== gotoItem && node !== nextItem);

            Array.prototype.forEach.call($replaceItems, ($item, i) => {
                $item.removeAttribute('data-kw-position');
                $item.classList.add('kw-hidden');
                $item.classList.remove('kw-visible', '-kw-hidden');
                // nextItem.classList.remove('-top-full');
                // nextItem.classList.add('-kw-hidden');
            });

            // console.log($replaceItems)
        }

        function cloneKeywords() {
            // console.log()
            for (let index = 0; index < $keywords.length; index++) {
                const element = $keywords[index];
                let listItem = document.createElement('span');
                listItem.append($keywords[index].innerHTML);
                $keywordsList.append(listItem);
                listItem.dataset.offset = listItem.offsetTop;

            }
        }
    },

    animShowcase: () => {
        var sections = gsap.utils.toArray('.card__project');

        sections.forEach((section) => {

            let thumbWrap = section.querySelector('.card__project-thumb')
            let thumbImage = thumbWrap.querySelector('img')

            let tl = gsap.timeline({
                // autoAlpha: 0,
                // scrollTrigger: {
                //     trigger: section,
                //     start: 'top 50%',
                //     scrub: true,
                //     end: '+=500',
                //     markers: true
                // }
            }).pause();
            // tl.addLabel("start")
            tl
                // .to("#showcase article", { backgroundColor: "#28a92b" })
                // .fromTo("#showcase article .card__project-thumb", { scaleY: 0 }, { scaleY: 1, duration: 0.4 })
                // .fromTo("#showcase article .card__project-thumb", { clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)" }, { clipPath: "polygon(0 100%, 100% 100%, 100% 0, 0 0)", duration: 0.8 })
                .fromTo(thumbWrap, { clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)" }, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: 0.8 })
                // .fromTo("#showcase article .card__project-thumb > img", { maxHeight: 0, y: 50 }, { maxHeight: "100%", y: 0, duration: 0.6 }, "-=1")
                .fromTo(thumbImage, { scale: 1.125 }, { scale: 1, duration: 0.6 }, "-=0.8")
                // .fromTo("#showcase article", { y: 100, duration: 1 }, { y: 0, duration: 1 })
                // .from(".w-xxl", { scale: 0.3, rotation: 45, autoAlpha: 0 })
                // .addLabel("color")
                // .addLabel("spin")
                // .to(".box", { rotation: 360 })
                .addLabel("end");

            // gsap.to(section, {

            // });
            ScrollTrigger.create({
                trigger: section,
                // markers: true,
                // once: true,
                // pin: true,   // pin the trigger element while active
                start: "top 40%", // when the top of the trigger hits the top of the viewport
                end: "+200px", // end after scrolling 500px beyond the start
                scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // snap: {
                //     snapTo: "labels", // snap to the closest label in the timeline
                //     duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
                onEnter: function () { tl.play() }
            });

        })

    },

    animKeywordsLine: () => {
        let $expertises = document.querySelector('#expertises');
        if (!$expertises) return;

        const tl = gsap.timeline({
            scrollTrigger: {
                scrub: 1,
                trigger: "#expertises",
                start: "top 100%",
                // end: "bottom 50%",
                // markers: true,
                // pin: true
            },
        });

        tl
            .fromTo("#expertises>ul",
                { translateX: 0, },
                { translateX: '-100%', ease: "sine.out" });

    },

    animExpertises: () => {
        var listItems = gsap.utils.toArray('#expertises ol li');

        listItems.forEach((item) => {

            let tl = gsap.timeline({
                // autoAlpha: 0,
                // scrollTrigger: {
                //     trigger: section,
                //     start: 'top 50%',
                //     scrub: true,
                //     end: '+=500',
                //     markers: true
                // }
            }).pause();

            tl.fromTo(item, { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 0.4 })
                .delay(0.2)

            ScrollTrigger.create({
                trigger: item,
                // markers: true,
                // once: true,
                // pin: true,   // pin the trigger element while active
                start: "top 100%", // when the top of the trigger hits the top of the viewport
                // end: "+200px", // end after scrolling 500px beyond the start
                scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // snap: {
                //     snapTo: "labels", // snap to the closest label in the timeline
                //     duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
                onEnter: function () { tl.play() }
            });

        })

    },

    animProjects: () => {
        var listItems = gsap.utils.toArray('.project-block');

        listItems.forEach((item) => {

            let tl = gsap.timeline({
                // autoAlpha: 0,
                // scrollTrigger: {
                //     trigger: section,
                //     start: 'top 50%',
                //     scrub: true,
                //     end: '+=500',
                //     markers: true
                // }
            }).pause();

            tl.fromTo(item, { opacity: 0, y: 10 }, { opacity: 1, y: 0, duration: 0.4 })
                .delay(0.2)

            ScrollTrigger.create({
                trigger: item,
                // markers: true,
                // once: true,
                // pin: true,   // pin the trigger element while active
                start: "top 100%", // when the top of the trigger hits the top of the viewport
                // end: "+200px", // end after scrolling 500px beyond the start
                scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
                // snap: {
                //     snapTo: "labels", // snap to the closest label in the timeline
                //     duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
                //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
                //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
                // }
                onEnter: function () { tl.play() }
            });

        })

    },

    animTeam: () => {
        let $team = document.querySelector('#team');
        if (!$team) return;

        let $gallery = $team.querySelector('.gallery-images');
        let $galleryText = $team.querySelector('#gallery-text');
        let $galleryItems = $gallery.querySelectorAll('li');
        // console.log($galleryItems.getBoundingClientRect().width);
        var imgs = document.images,
            len = imgs.length,
            counter = 0;

        [].forEach.call(imgs, function (img) {
            if (img.complete)
                incrementCounter();
            else
                img.addEventListener('load', incrementCounter, false);
        });

        window.addEventListener('resize', () => {
            incrementCounter();
        });

        function incrementCounter() {

            counter++;
            if (counter === len) {
                // let length = 0;
                // let $galleryList = document.querySelector('.gallery-images__list');
                // let length = $galleryList.scrollWidth;
                // $galleryList.style.left = length + 'px';
                // console.log(length);
                // console.log('All images loaded!');
                Array.prototype.forEach.call($galleryItems, ($galleryItem, i) => {
                    // console.log($gallery.scrollWidth, $gallery.getBoundingClientRect().width);
                    // length += i === 0 ? $galleryItem.scrollWidth - $gallery.getBoundingClientRect().width + 2 * 16 : $galleryItem.scrollWidth
                    length += i === 0 ? $galleryItem.scrollWidth - $gallery.getBoundingClientRect().width + 2 * 16 : $galleryItem.scrollWidth
                    // length += i === 0 ? $galleryItem.scrollWidth
                });
                // console.log(length);

                const tl = gsap.timeline({
                    defaults: { duration: 1 },
                    scrollTrigger: {
                        scrub: 1,
                        trigger: "#team",
                        start: "top 0",
                        end: "=+1000",
                        // markers: true,
                        pin: true,
                        // anticipatePin: 1,
                    },
                });
                // length = length - 912;
                // console.log(length)


                tl
                    .fromTo("#gallery-text",
                        { opacity: 1 },
                        { opacity: 0, duration: 0.85, })
                    .fromTo(".gallery-images__list",
                        { translateX: '100%', opacity: 0 },
                        // { translateX: length + 'px', opacity: 0 },
                        // { translateX: '-' + length + 'px', opacity: 1, duration: 2 }, "-=.8")
                        { translateX: 0, left: '-' + length + 'px', opacity: 1, duration: 2 }, "-=.8")
                    .delay(0.5);
                // { translateX: '-' + length + 'px', });

                tl.scrollTrigger.refresh();
            }
        }

    },

    strateContact: () => {
        // let $contact = document.querySelector('#contact');

        // const tl = gsap.timeline({
        //     scrollTrigger: {
        //         scrub: 1,
        //         trigger: "#contact",
        //         // start: "top 60%",
        //         // end: "bottom 50%",
        //         markers: true,
        //         // pin: true
        //     },
        // });

        // tl
        //     .fromTo("#contact>*", {
        //         opacity: 0,
        //     }, { opacity: 1 });

        // var tl = gsap.timeline(/*{repeat: 2, repeatDelay: 1}*/);
        // tl.from("#showcase article", { y: 100, duration: 1 })
        //     .to("#showcase article", { y: 0, duration: 1 });
    },

    // timelineShowcase: () => {

    //     var tl = gsap.timeline(/*{repeat: 2, repeatDelay: 1}*/);
    //     tl.from("#showcase article", { y: 100, duration: 1 })
    //         .to("#showcase article", { y: 0, duration: 1 });

    // },

    // Tests
    scrollAnim: () => {
        const animData = {
            "project-block": { opacity: 0, y: 10 }
        };

        for (let p in animData) {
            gsap.utils.toArray("." + p).forEach(el => {
                gsap.from(el, {
                    scrollTrigger: {
                        trigger: el,
                        start: "top 80%",
                        // markers: true,
                        scrub: true,
                        toggleActions: "play pause resume reverse" // do whatever you want here. 
                    },
                    ...animData[p]
                });
            });
        }
    },
    scrollAnim2: () => {
        let $showcase = '#showcase';
        hideGallery();

        function animateGallery(elem) {
            gsap.set(elem, { autoAlpha: 1 });

            // let timelineGallery = new TimelineMax();

            // let galleryItems = '.section--gallery .gallery--grid-item';

            // timelineGallery.fromTo(
            //     elem,
            //     { opacity: 0, /*height: 0*/ },
            //     { opacity: 1, /*height: "auto"*/ },
            //     //"headerEnd+=2"
            // ).fromTo(
            //     galleryItems,
            //     { opacity: 0, y: 20 },
            //     { opacity: 1, y: 0, stagger: 0.1 }
            // );
            // timelineGallery.duration(2)
            let tl = gsap.timeline({
            });
            tl.addLabel("start")
            tl
                // .to("#showcase article", { backgroundColor: "#28a92b" })
                // .fromTo("#showcase article .card__project-thumb", { scaleY: 0 }, { scaleY: 1, duration: 0.4 })
                // .fromTo("#showcase article .card__project-thumb", { clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)" }, { clipPath: "polygon(0 100%, 100% 100%, 100% 0, 0 0)", duration: 0.8 })
                .fromTo("#showcase article .card__project-thumb", { clipPath: "polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)" }, { clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)", duration: 0.8 })
                // .fromTo("#showcase article .card__project-thumb > img", { maxHeight: 0, y: 50 }, { maxHeight: "100%", y: 0, duration: 0.6 }, "-=1")
                .fromTo("#showcase article .card__project-thumb > img", { scale: 1.125 }, { scale: 1, duration: 0.6 }, "-=0.8")
                // .fromTo("#showcase article", { y: 100, duration: 1 }, { y: 0, duration: 1 })
                // .from(".w-xxl", { scale: 0.3, rotation: 45, autoAlpha: 0 })
                // .addLabel("color")
                // .addLabel("spin")
                // .to(".box", { rotation: 360 })
                .addLabel("end");
        };

        function hideGallery() {
            gsap.set($showcase, {/*height: 0,*/ autoAlpha: 0 });
        }

        // yes, we can add it to an entire timeline!
        ScrollTrigger.create({
            trigger: $showcase,
            // markers: true,
            // once: true,
            // pin: true,   // pin the trigger element while active
            start: "top 80%", // when the top of the trigger hits the top of the viewport
            // end: "100px", // end after scrolling 500px beyond the start
            scrub: true, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
            // snap: {
            //     snapTo: "labels", // snap to the closest label in the timeline
            //     duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            //     delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            //     ease: "power1.inOut" // the ease of the snap animation ("power3" by default)
            // }
            onEnter: function () { animateGallery($showcase) }
        });

        // add animations and labels to the timeline

    },

    // ui: () => {

    //     function setHeaderPosition() {
    //         // Get DIVI Header height as CSS Var
    //         let $header = document.querySelector('#main-header');
    //         setTimeout(() => {
    //             let headerHeight = parseInt($header.dataset.fixedHeightOnload) > 0 ? $header.dataset.fixedHeightOnload : $header.dataset.heightOnload;
    //             document.documentElement.style.setProperty('--headerh', `${headerHeight}px`);
    //         }, 100)
    //     }

    //     setHeaderPosition();
    //     window.addEventListener('resize', setHeaderPosition);
    // }

}